import BenefitTypeRepository from '@/shared/http/repositories/socialProject/benefit-type'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewRegister from '@/components/layout/NewRegister/newRegister.vue'
import BenefitType from '@/shared/models/benefitType'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'NovoTipoDeBeneficio',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewRegister.name]: NewRegister
  },

  data() {
    return {
      placeholder: 'Nome',
      title: 'Tipo de Benefício',
      benefitType: new BenefitType(),
      isNew: true
    }
  },

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Tipos de Benefício',
            href: this.$router.resolve({ name: 'BenefitType' }).href
          },
          {
            text: 'Novo tipo de benefício',
            active: true
          }
        ]
      }
    }
  },

  methods: {
    create(benefitType) {
      loading.push()
      BenefitTypeRepository.Create(benefitType)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Tipo de Benefício criado com sucesso', 'NOVO TIPO DE BENEFÍCIO')
          Promise.resolve()
          return this.$router.replace({ name: 'BenefitType' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar tipo de benefício', 'ERRO')
        })
    }
  }
}
